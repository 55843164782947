.btn {
    &,
    &:link,
    &:visited {
        background: linear-gradient(to right, $grey-light-1, $grey-light-2);
        color: $black;   
        text-decoration: none;
        padding: 1.5rem 4rem;
        display: inline-block;
        border-radius: 2rem;
        transition: all .2s;
        font-size: $default-font-size;

        //Change for the <button> element
        border: none;
        cursor: pointer;
    }
        
    &:hover {
        color: $marzored;
        background: linear-gradient(to left, $grey-light-1, $grey-light-2);
        box-shadow: 0 1rem 2rem rgba($black,.2);
        transform: translateY(-3px);

        &::after {
            transform: scaleX(1.4) scaleY(1.6);
            opacity: 0;
        }
    }
    
    &:active,
    &:focus {
        outline: none;
        transform: translateY(-1px);
        box-shadow: 0 .5rem 1rem rgba($black,.2);
    }

}