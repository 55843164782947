
.heading {
    @include absCenter;
    line-height: 0.8;

    &__primary {
        display: flex;
        align-items: center;
        width: 100rem;

        @include respond(phone) {
            width: 51rem;
        }

        @include respond(sm-phone) {
            width: 40rem; 
        }
    }

    &__primary-tittle {
        color: $black;
        height: 17rem;        
        font-size: 21rem;
        font-weight: 100;
        letter-spacing: -2rem;
        margin: 1rem auto 1rem 0;
        padding: 1rem 0;
        text-transform: uppercase;

        @include respond(phone) {
            font-size: 10rem; 
            letter-spacing: -1rem;
            height: 9rem;    
        }

        @include respond(sm-phone) {
            font-size: 8rem; 
            height: 8rem;  
        }
    }

    &__primary-square {
        color: $black;
        border: 2rem solid $marzored;
        height: 15rem;
        width: 25rem;
        margin: 1rem 0;
        padding: 1rem 0;

        @include respond(phone) {
            border: 1.2rem solid $marzored;
            height: 7.5rem;
            width: 12rem;
        }

        @include respond(sm-phone) {
            font-size: 8rem; 
        }
    }

    &__secondary {
        color: $black;
        font-style: normal;
        font-size: 10rem;
        font-weight: 100;
        margin: 1rem 0;
        padding: 1rem 0;

        @include respond(phone) {
            font-size: 6rem; 
        }

        @include respond(sm-phone) {
            font-size: 3rem; 
        }
    }

    &__tertiary {
        color: $white;
        font-style: normal;
        font-size: 5rem;
        font-weight: 100;
        margin: 1rem 0;
        padding: 1rem 0;
    }
}

