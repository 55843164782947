* {    
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%; //1rem = 10px; 10px/16px = 62.5%

    @include respond(tab-land) {
        font-size: 56.25%
    }

    @include respond(tab-port) {
        font-size: 50%;
    }

    @include respond(phone) {
        font-size: 43.75%;
    }
}

body {
    @include absCenter;
    color: $background-red;
    background-image: url(/assets/images/new_york.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    /* border: 5rem solid red; */
    font-family: 'theano_didotregular';
    font-weight: 100;
    height: 100vh;
    justify-content: center;

    @include respond(tab-port) {
        background-position: center;
    }
}