@mixin absCenter {
    display: flex;
	align-items: center;
    flex-direction: column;
}

//Mediaqueries

//MEDIA QUERIES MANAGER
/* 
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet Landscape
[1200 - 1800]   is where our normal styles apply
1800px +        Big DESKTOP

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop
*/

@mixin respond ($breakpoint) {
    @if $breakpoint == sm-phone {
        @media only screen and (max-width: 26.25em) {
            @content;
        }
    }
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) {
            @content;
        }
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) {
            @content;
        }
    } 
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) {
            @content;
        }
    } 
    @if $breakpoint == big-desktop {
        @media only screen and (max-width: 112.5em) {
            @content;
        }
    } 
}
