//COLORS

$background-red:         #D31027;
$background-red-light:   #EA384D;

$primary-red:            #eb2f64;
$primary-red-light:      #FF3366;
$primary-red-dark:       #BA265D;

$marzored:               #DA000F;

$green:                  #55c57a;
$green-light:            #7ed56f;
$green-dark:             #28b485;

$color-secondary-light:  #ffb900;
$color-secondary-dark:   #ff7730;

$blue-light:             #2998ff;
$blue-dark:              #5643fa;

$grey-dark:              #777;
$grey-dark-2:            #999;
$grey-dark-3:            #333;
$grey-light-1:           #f7f7f7;
$grey-light-2:           #eee;

$white:                  #fff;

$black:                  #000;

$color-grey-light-1:     #faf9f9;
$color-grey-light-2:     #f4f2f2;
$color-grey-light-3:     #f0eeee;
$color-grey-light-4:     #ccc;


//SHADOWS
$shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
$shadow-light: 0 2rem 5rem rgba(0,0,0,.06);

//FONT SIZES
$default-font-size: 1.6rem;